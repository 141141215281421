import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import aboutUs from "../images/aboutUs.png"
import logo from "../images/smLogoblueW.png"

const AboutPage = props => (
  <Layout>
    <SEO title="About Skyrocket Digital Marketing" />
    <div className="flex-row flex-wrap mb-32 md:flex px-10">
      <div className="px-4 flex-1">
        <h1 className="text-5xl font-extrabold mb-1 xs:text-lg uppercase">
          About Us
        </h1>
        <img src={logo} alt="Skyrocket Digital Marketing" className={"h-16"} />

        <p>
          Located in Toronto, Canada, Skyrocket Digital Marketing is a 100%
          result driven group of highly talented and self-motivated individuals,
          having the passion of Digital Media and Conversational Marketing.
        </p>
        <p>
          As a full-service digital marketing company, we offer our clients a
          powerful range of services and solutions to enhance their social media
          presence, boost lead conversion, and skyrocket their digital marketing
          game to the next level.
        </p>
        <p>
          Our mission is to understand what you need to succeed, by providing
          insightful business consultation, and help you make powerful and
          effective decisions that will increase your ROI and performance. We’re
          dedicated to help you keep growing and build a strong online presence
          of your business, every day.
        </p>
        <Link to="/contact/">
          <button className="bg-skblue-100 hover:shadow text-white font-bold py-2 px-4 rounded shadow-2xl focus:outline-none focus:shadow-outline">
            Let's Get Started
          </button>
        </Link>
      </div>
      <div className="px-4 flex-1">
        <img src={aboutUs} alt={"About Skyrocket Marketing"} />
      </div>
    </div>
  </Layout>
)

export default AboutPage
